import React, { useState } from 'react';
import './Faq.css';

const Faq = () => {
    const [expanded, setExpanded] = useState({
        privacy: false,
        howItWorks: false,
        updates: false // 添加更新公告的展开状态
    });

    const toggleExpand = (section) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [section]: !prevExpanded[section]
        }));
    };

    return (
        <div className="faq-container">
            <h1>常见问题解答</h1>

            {/* 隐私政策 */}
            <div className="faq-item">
                <h2 onClick={() => toggleExpand('privacy')} className="faq-title">
                    <span className="faq-arrow">{expanded.privacy ? '▲' : '▼'}</span> 隐私政策
                </h2>
                <div className={`faq-content ${expanded.privacy ? 'expanded' : ''}`}>
                    {expanded.privacy && (
                        <>
                            <p>最后更新日期：2024 年 6 月 4 日</p>
                            <p>
                                本隐私政策阐述了 Zistock AI,
                                Inc.（以下简称“我们”或“公司”）如何收集、使用、存储和分享您在使用我们的网站、应用程序、服务、工具及其他功能时提供或生成的个人信息（以下简称“服务”）。本政策适用于所有中文圈用户，无论您是客户、访客、求职者，还是作为公司代表与我们进行业务往来的个人。
                            </p>
                            <p>
                                在您使用我们的服务之前，请务必仔细阅读本隐私政策。一旦您访问或使用我们的服务，即表示您同意我们按照本隐私政策中的规定，收集、使用和分享您的个人信息。如果您不同意本政策的内容，请立即停止使用服务。
                            </p>
                            <h3>政策更新</h3>
                            <p>
                                我们可能会不定期更新本隐私政策。每次更新后，您在使用服务时将受新的隐私政策约束。我们将更新本隐私政策的“最后更新日期”以反映任何变更。如果对个人信息的处理方式发生重大变更，我们会采取合理措施通知您，例如通过发送电子邮件到您提供的最后一个有效邮箱地址，或在我们的服务页面发布通知，并根据适用的法律法规采取必要的进一步措施。如果您不同意更新后的政策，您应立即停止使用我们的服务。
                            </p>
                            <h3>信息的收集与使用</h3>
                            <p>
                                我们在您使用服务的过程中，可能会从多种来源收集您的个人信息。这些信息包括但不限于：注册信息、交易记录、设备信息、浏览历史、位置信息及其他可能与服务相关的数据。我们会根据具体情况，将这些信息用于优化服务、提升用户体验、提供个性化内容和广告、维护平台安全及满足法律合规要求。
                            </p>
                            <h3>信息的分享与披露</h3>
                            <p>
                                我们不会与无关第三方分享您的个人信息，除非在以下情况中：为履行合同或提供服务所必要；为遵守法律法规要求；为保护公司的合法权益；或在获得您的明确同意后进行分享。我们会采取合理措施，确保所有共享信息的处理遵循相关隐私法律法规。
                            </p>
                        </>
                    )}
                </div>
            </div>

            {/* 如何工作 */}
            <div className="faq-item">
                <h2 onClick={() => toggleExpand('howItWorks')} className="faq-title">
                    <span className="faq-arrow">{expanded.howItWorks ? '▲' : '▼'}</span> ZiStock 智投搜索引擎 如何工作
                </h2>
                <div className={`faq-content ${expanded.howItWorks ? 'expanded' : ''}`}>
                    {expanded.howItWorks && (
                        <>
                            <p>
                                Zistock 智投搜索引擎提供四种强大的搜索模式，帮助您更好地获取所需信息。这些模式分别是普通模式、学术搜索、全网搜索和新闻搜索。
                            </p>
                            <h3>普通模式</h3>
                            <p>
                                在普通模式下，Zistock 使用先进的 GPT-4 模型直接回答您的问题。此模式不进行联网搜索，而是利用 GPT-4 强大的语言处理能力，基于已有知识生成答案，适合快速获取通用信息。
                            </p>
                            <h3>学术搜索</h3>
                            <p>
                                学术搜索专为需要查找学术文章和研究文献的用户设计。Zistock 会在学术数据库中搜索相关论文、期刊文章，并将其提炼成简明易懂的答案，帮助您获取深入的研究资料。
                            </p>
                            <h3>全网搜索</h3>
                            <p>
                                全网搜索是为需要广泛信息的用户提供的功能。Zistock 会扫描整个互联网，收集来自各类网站、博客和权威来源的最新信息，提供全面的搜索结果，适合需要全面了解某一话题的用户。
                            </p>
                            <h3>新闻搜索</h3>
                            <p>
                                新闻搜索模式专注于新闻内容，Zistock 会从全球新闻网站和媒体平台中筛选最新的新闻报道，为您提供实时的新闻动态。无论是财经新闻、时事热点，还是行业新闻，Zistock 都能快速为您找到。
                            </p>
                            <h3>特点</h3>
                            <h4>类人对话</h4>
                            <p>
                                无论使用哪种搜索模式，Zistock 都以对话方式响应您的查询，像与专家对话一样简明清晰。
                            </p>
                            <h4>来源透明</h4>
                            <p>
                                每个答案都会引用信息来源，方便您验证或进一步查阅详细内容。
                            </p>
                            <h3>示例</h3>
                            <p>
                                例如，如果您在全网搜索模式下询问“当前股市趋势”，Zistock 会：
                            </p>
                        </>
                    )}
                </div>
            </div>

            {/* 新增的更新公告 */}
            <div className="faq-item">
                <h2 onClick={() => toggleExpand('updates')} className="faq-title">
                    <span className="faq-arrow">{expanded.updates ? '▲' : '▼'}</span> 更新公告
                </h2>
                <div className={`faq-content ${expanded.updates ? 'expanded' : ''}`}>
                    {expanded.updates && (
                        <>
                            <h3>V1.0 更新公告</h3>
                            <ul>
                                <li>AI搜索引擎目前带有返回结果的索引点击可以导航到相关出处的网页。</li>
                                <li>优化了历史聊天内容，增加了“今天”、“昨天”和“过去七天”的时间段分类。</li>
                                <li>增加了对研报过多内容的分页显示功能。</li>
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Faq;
