import React, { useState, useEffect, useRef } from 'react';
import { ListItem, ListItemText, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import eventEmitter from '../SearchPage/Chatbox/EventEmitter';
import serverAddress from "../../ServerAddress";
import dayjs from 'dayjs';  // 使用 dayjs 进行时间处理

const RecentChats = () => {
    const [chats, setChats] = useState([]);
    const [userId, setUserId] = useState(localStorage.getItem('user_id'));
    const [username] = useState(localStorage.getItem('username'));
    const [initialFetchDone, setInitialFetchDone] = useState(false);
    const userIdRef = useRef(userId);
    const navigate = useNavigate();

    const fetchChats = async (userId) => {
        try {
            const token = localStorage.getItem('access_token');
            console.log("userid is", userId);
            const response = await fetch(`${serverAddress()}/recent/get_titles?user_id=${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch titles');
            }

            const data = await response.json();
            const sortedChats = data.titles.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

            const chatTitles = sortedChats.map(chat => ({
                id: chat.chat_id,
                title: chat.title,
                created_at: chat.created_at
            }));

            localStorage.setItem('recentChats', JSON.stringify(chatTitles));
            setChats(chatTitles);
        } catch (error) {
            console.error('Error fetching chats:', error);
        }
    };

    const groupChatsByDate = (chats) => {
        const today = dayjs().startOf('day');
        const yesterday = today.subtract(1, 'day');
        const sevenDaysAgo = today.subtract(7, 'day');

        const grouped = {
            today: [],
            yesterday: [],
            last7days: [],
            earlier: []
        };

        chats.forEach(chat => {
            const chatDate = dayjs(chat.created_at);
            if (chatDate.isSame(today, 'day')) {
                grouped.today.push(chat);
            } else if (chatDate.isSame(yesterday, 'day')) {
                grouped.yesterday.push(chat);
            } else if (chatDate.isAfter(sevenDaysAgo)) {
                grouped.last7days.push(chat);
            } else {
                grouped.earlier.push(chat);
            }
        });

        return grouped;
    };

    useEffect(() => {
        const cachedChats = localStorage.getItem('recentChats');
        if (cachedChats) {
            setChats(JSON.parse(cachedChats));
        }

        if (userId && username && !initialFetchDone) {
            fetchChats(userId);
            setInitialFetchDone(true);
        } else if (!userId) {
            console.error('User ID not found in local storage');
        }

        const observer = new MutationObserver(() => {
            const newUserId = localStorage.getItem('user_id');
            if (userIdRef.current !== newUserId) {
                userIdRef.current = newUserId;
                setUserId(newUserId);
                fetchChats(newUserId);
            }
        });

        const config = { subtree: true, childList: true, attributes: true };

        observer.observe(document, config);

        return () => observer.disconnect();
    }, [userId, username, initialFetchDone]);

    useEffect(() => {
        const handleChatUpdated = (userId) => {
            fetchChats(userId);
        };

        eventEmitter.on('chatUpdated', handleChatUpdated);

        return () => {
            eventEmitter.off('chatUpdated', handleChatUpdated);
        };
    }, []);

    const handleChatClick = async (chatId) => {
        const userId = localStorage.getItem('user_id');
        const token = localStorage.getItem('access_token');
        try {
            const response = await fetch(`${serverAddress()}/recent/get_chat_sessions?user_id=${userId}&chat_id=${chatId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Chat sessions:', data.chats);
            navigate(`/chat/${chatId}`, { state: { chatSessions: data.chats } });
        } catch (error) {
            console.error('Error fetching chat sessions:', error);
        }
    };

    const groupedChats = groupChatsByDate(chats);

    return (
        <Box sx={{ position: 'relative', ml: 0 }}>
            <Box sx={{ position: 'absolute', left: 16, top: 0, bottom: 0, width: 2, bgcolor: 'grey.400', opacity: 0.3 }} />
            <Box sx={{ pl: 3 }}>  {/* 增加 padding-left */}
                {groupedChats.today.length > 0 && (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'black',
                            fontWeight: 'bold',  /* 加粗字体 */
                            mb: 1
                        }}
                    >
                        今天
                    </Typography>
                )}
                {groupedChats.today.map((chat) => (
                    <ListItem
                        key={chat.id}
                        sx={{ py: 0.5, pl: 0 }}
                        disablePadding
                        onClick={() => handleChatClick(chat.id)}
                    >
                        <Box
                            sx={{
                                width: 'calc(100% - 4px)',
                                ml: '4px',
                                '&:hover': {
                                    bgcolor: 'grey.200',
                                },
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '170px',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        {chat.title}
                                    </Typography>
                                }
                                primaryTypographyProps={{ fontSize: '0.875rem' }}
                                sx={{ pl: 1, pr: 1 }}
                            />
                        </Box>
                    </ListItem>
                ))}

                {groupedChats.yesterday.length > 0 && (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'black',
                            fontWeight: 'bold',  /* 加粗字体 */
                            mb: 1
                        }}
                    >
                        昨天
                    </Typography>
                )}
                {groupedChats.yesterday.map((chat) => (
                    <ListItem
                        key={chat.id}
                        sx={{ py: 0.5, pl: 0 }}
                        disablePadding
                        onClick={() => handleChatClick(chat.id)}
                    >
                        <Box
                            sx={{
                                width: 'calc(100% - 4px)',
                                ml: '4px',
                                '&:hover': {
                                    bgcolor: 'grey.200',
                                },
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '170px',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        {chat.title}
                                    </Typography>
                                }
                                primaryTypographyProps={{ fontSize: '0.875rem' }}
                                sx={{ pl: 1, pr: 1 }}
                            />
                        </Box>
                    </ListItem>
                ))}

                {groupedChats.last7days.length > 0 && (
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'black',
                            fontWeight: 'bold',  /* 加粗字体 */
                            mb: 1
                        }}
                    >
                        过去七天
                    </Typography>
                )}
                {groupedChats.last7days.map((chat) => (
                    <ListItem
                        key={chat.id}
                        sx={{ py: 0.5, pl: 0 }}
                        disablePadding
                        onClick={() => handleChatClick(chat.id)}
                    >
                        <Box
                            sx={{
                                width: 'calc(100% - 4px)',
                                ml: '4px',
                                '&:hover': {
                                    bgcolor: 'grey.200',
                                },
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Typography
                                        component="span"
                                        sx={{
                                            display: 'inline-block',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '170px',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        {chat.title}
                                    </Typography>
                                }
                                primaryTypographyProps={{ fontSize: '0.875rem' }}
                                sx={{ pl: 1, pr: 1 }}
                            />
                        </Box>
                    </ListItem>
                ))}
            </Box>
        </Box>
    );
};

export default RecentChats;
