// UserProfile.js
import React from 'react';
import { Box, Avatar, Typography, IconButton, Menu, MenuItem, Divider, LinearProgress, ListItem, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from 'react-router-dom';

function UserProfile({
                         isOpen,
                         isLoggedIn,
                         loading,
                         username,
                         handleAvatarClick,
                         anchorEl,
                         handleMenuClose,
                         handleLogout,
                         handleOpenModal,
                         displayUsername,
                     }) {
    const navigate = useNavigate();

    return (
        isOpen && (
            <Box sx={{ px: 0, mx: 0, backgroundColor: '#fafafa', marginTop: 0 }}>
                {isLoggedIn && <Divider sx={{ marginBottom: 1, backgroundColor: '#333' }} />}
                <Box sx={{ padding: '0 8px', height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 0 }}>
                    {loading ? (
                        <LinearProgress sx={{ width: '100%' }} />
                    ) : (
                        isLoggedIn ? (
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}>
                                    <Avatar onClick={handleAvatarClick} sx={{ cursor: 'pointer', bgcolor: 'primary.main', marginRight: 1, width: 32, height: 32 }}>
                                        {username.charAt(0).toUpperCase()}
                                    </Avatar>
                                    <Typography variant="h6">
                                        {displayUsername(username)}
                                    </Typography>
                                </Box>
                                <IconButton onClick={handleAvatarClick}>
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={handleLogout}>
                                        <Typography variant="body2">登出</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={() => navigate('/favorites')}>
                                        <Typography variant="body2">我的收藏</Typography>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        ) : (
                            <ListItem sx={{ padding: '0 8px' }}>
                                <Button
                                    onClick={handleOpenModal}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    startIcon={<LockIcon />}
                                    sx={{
                                        margin: '8px 0',
                                        borderRadius: '16px',
                                        height: '48px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textTransform: 'none',
                                        width: '100%',
                                    }}
                                >
                                    登录/注册
                                </Button>
                            </ListItem>
                        )
                    )}
                </Box>
            </Box>
        )
    );
}

export default UserProfile;
