// SidebarHeader.js
import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function SidebarHeader({ isOpen, handleToggleSidebar }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 56, paddingLeft: 2 }}>
            {isOpen && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/logo/favicon_io/android-chrome-192x192-removebg-preview.png" alt="Logo" style={{ height: 40, marginRight: '8px' }} />
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: '600',
                            marginLeft: '8px',
                            letterSpacing: '0.1rem',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        ZiStock
                    </Typography>
                </Box>
            )}
            <IconButton onClick={handleToggleSidebar} style={{ marginRight: '16px', marginLeft: isOpen ? '0px' : '-8px' }}>
                {isOpen ? (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <path d="M11 18L6 12L11 6" />
                        <path d="M11 12H19" />
                        <path d="M19 2V22" />
                    </svg>
                ) : <MenuIcon />}
            </IconButton>
        </Box>
    );
}

export default SidebarHeader;
