import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import PdfChatbox from './PdfChatbox';
import serverAddress from "../../ServerAddress"; // Ensure the correct path

function PdfViewerPage() {
    const { book } = useParams();
    const [pdfUrl, setPdfUrl] = useState('');
    const location = useLocation();
    const isSidebarOpen = location.state?.isSidebarOpen ?? true; // Retrieve sidebar state from location
    const mode = location.state?.mode ?? 'book'; // Retrieve mode from location
    const companyName = location.state?.companyName ?? ''; // Retrieve company_name from location
    const stocktype = location.state?.stocktype ?? ''; // Retrieve stocktype from location

    useEffect(() => {
        console.log('Received book parameter:', book);
        console.log('Received mode parameter:', mode);
        console.log('Received companyName parameter:', companyName);
        console.log('Received stocktype parameter:', stocktype);
        const fileExtension = mode === 'Financial' ? 'PDF' : 'pdf';
        // Fetch the PDF URL from the backend
        const fetchUrl = new URL(
            serverAddress().startsWith('http')
                ? `${serverAddress()}/p/${encodeURIComponent(book)}.${fileExtension}`
                : `${window.location.origin + serverAddress()}/p/${encodeURIComponent(book)}.${fileExtension}`
        );

        fetchUrl.searchParams.append('mode', mode);
        if (companyName) {
            fetchUrl.searchParams.append('company_name', companyName);
        }
        if (stocktype) {
            fetchUrl.searchParams.append('stocktype', stocktype);
        }

        fetch(fetchUrl.toString())
            .then(response => response.json())
            .then(data => {
                if (data.url) {
                    setPdfUrl(data.url); // Set the PDF URL from the response
                } else {
                    throw new Error('Failed to fetch PDF URL');
                }
            })
            .catch(error => {
                console.error('Error fetching PDF URL:', error);
            });
    }, [book, mode, companyName, stocktype]);

    // Build the viewer.html URL with the PDF URL as a parameter
    const viewerUrl = `/pdfjs/web/viewer.html?file=${encodeURIComponent(pdfUrl)}`;

    return (
        <Box
            sx={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                overflow: 'hidden',
                paddingLeft: { xs: '0px', md: isSidebarOpen ? '240px' : '60px' },
                paddingRight: { xs: '0px', md: isSidebarOpen ? '240px' : '60px' },
                transition: 'padding-left 0.3s ease, padding-right 0.3s ease',
                '@media (max-width: 600px)': {
                    width: '100vw',
                    paddingLeft: '10px',  // 为移动设备添加左边距
                    paddingRight: '0px',
                }
            }}
        >


            <Grid container sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                <Grid item xs={12} md={8}
                      sx={{
                          height: { xs: '60%', md: '100%' },
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 0,
                          overflow: 'hidden',
                          width: 'calc(100% - 50px)',  // 调整宽度以减少左右空白
                      }}>
                    {pdfUrl && (
                        <iframe
                            title="PDF Viewer"
                            src={viewerUrl}
                            width="100%"
                            height="100%"
                            style={{ border: 'none', margin: '0 auto' }} // 居中显示
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={4}
                      sx={{
                          height: { xs: '40%', md: '100%' }, // Adjust height for mobile to 40%
                          display: 'flex',
                          flexDirection: 'column',
                          padding: 0,
                          overflow: 'hidden'
                      }}>
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '8px',
                        borderLeft: { xs: 'none', md: '1px solid #ccc' }, // Remove border for mobile
                        borderTop: { xs: '1px solid #ccc', md: 'none' }, // Add top border for mobile
                        height: '100%'
                    }}>
                        <PdfChatbox mode={mode} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PdfViewerPage;
