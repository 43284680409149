import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChatboxVisibilityProvider } from './contexts/ChatboxVisibilityContext';
import Sidebar from './components/Sidebar/Sidebar';
import SearchPage from './components/SearchPage/SearchPage';
import SearchResultPage from './components/SearchPage/SearchResultPage/SearchResultPage';
import Chatbox from './components/SearchPage/Chatbox/Chatbox';
import NewsPage from './components/NewsPage/NewsPage';
import AnalysisPage from './components/AnalysisPages/AnalysisPages.jsx';
import CompanyPage from './components/AnalysisPages/CompanyPages/CompanyPage.jsx';
import LearnPage from './components/LearnPage/LearnPage.jsx';
import PdfViewerPage from './components/PdfViewer/PdfViewer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import FavoritesPage from './components/Sidebar/FavoritesPage/FavoritesPage';
import Contact from './components/SearchPage/Footer/Contact/Contact.jsx';
import Faq from './components/SearchPage/Footer/Faq/Faq.jsx'; // 导入 FAQ 组件
import StockChartTest from './components/ImgaeDisplay/StockChartTest'; // 导入 StockChartTest 组件
import './App.css';

function App() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // 这行代码用于确保浏览器显示提示框
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const mainContentStyle = {
        flexGrow: 1,
        padding: '0',
        transition: 'margin-left 0.3s ease, margin-right 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    };

    return (
        <ChatboxVisibilityProvider>
            <div className="App" style={{ display: 'flex', height: '100vh' }}>
                <Sidebar
                    isOpen={isSidebarOpen}
                    toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
                    isLoggedIn={isLoggedIn}
                    setIsLoggedIn={setIsLoggedIn}
                    username={username}
                    setUsername={setUsername}
                />
                <div className="main-content" style={mainContentStyle}>
                    <Routes>
                        <Route path="/" element={<SearchPage />} />
                        <Route path="/search" element={<SearchPage />} />
                        <Route path="/chat/:chatid" element={<Chatbox isSidebarOpen={isSidebarOpen} />} />
                        <Route path="/results" element={<SearchResultPage />} />
                        <Route path="/hotnews" element={<NewsPage />} />
                        <Route path="/analysis" element={<AnalysisPage />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/favorites" element={<FavoritesPage />} />
                        <Route path="/company-analysis/:companyName" element={<CompanyPage />} />
                        <Route path="/learn" element={<LearnPage />} />
                        <Route path="/pdf-viewer/:book" element={<PdfViewerPage />} />
                        <Route path="/faq" element={<Faq />} /> {/* 添加 FAQ 路由 */}
                        <Route path="/test-stock-charts" element={<StockChartTest />} /> {/* 添加测试路由 */}
                    </Routes>
                </div>
            </div>
        </ChatboxVisibilityProvider>
    );
}

export default function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}
