import React from 'react';
import { Box, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';

const RenderFinancialReport = ({ item, displayName, onReportClick }) => {
    return (
        <Box
            key={item.id}
            sx={{ marginBottom: 2, borderBottom: '1px solid lightgray', cursor: 'pointer', '&:hover': { backgroundColor: '#f9f9f9' } }}
            onClick={() => onReportClick(item)}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{item.title}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', color: 'gray' }}>
                        {item.source !== '未知来源' && (
                            <Typography variant="body2">{item.source}</Typography>
                        )}
                        <Typography variant="body2" sx={{ marginLeft: item.source !== '未知来源' ? '4px' : '0' }}>2024-07-05</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="body1">
                    <strong>摘要：</strong>
                </Typography>
                <ReactMarkdown>{item.summary}</ReactMarkdown>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <ReactMarkdown>{item.content}</ReactMarkdown>
            </Box>
            <Typography variant="body2" sx={{ color: 'gray', textAlign: 'left', marginBottom: 2 }}>
                AI也会犯错，不构成任何投资建议
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: '40px', height: '40px', borderRadius: '50%', overflow: 'hidden', marginRight: '8px' }}>
                        <img src={`/CompanyImages/${displayName}.png`} alt={displayName} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    </Box>
                    <Typography variant="body2">{displayName}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default RenderFinancialReport;
