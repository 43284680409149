import { styled } from '@mui/system';
import { Box, Card, Avatar, TextField } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    backgroundColor: '#fff',
    textAlign: 'center',
    cursor: 'pointer',
}));

export const CommonAvatar = styled(Avatar)(({ theme }) => ({
    backgroundColor: '#f5f5f5',
    color: '#000',
    width: 60,
    height: 60,
    marginBottom: theme.spacing(1),
}));

export const BookCover = styled(Box)(({ theme }) => ({
    width: 120,
    height: 160,
    backgroundColor: '#f5f5f5',
    marginBottom: theme.spacing(1),
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '5px',
    },
}));

export const CompanyLogo = styled(Box)(({ theme }) => ({
    width: 120,
    height: 120,
    backgroundColor: '#f5f5f5',
    marginBottom: theme.spacing(1),
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        borderRadius: '5px',
    },
}));

export const SearchBox = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: '300px',
    },
}));

export const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid #e0e0e0',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(4),
    '& .logo': {
        width: '250px', // 设置 logo 的宽度为 250px
        height: 'auto', // 高度自适应，保持图像的比例
        display: 'flex',
        justifyContent: 'center',
    },
}));
