import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Button, ToggleButton, ToggleButtonGroup, CircularProgress, Snackbar, Alert, IconButton, Pagination } from '@mui/material';
//import moment from 'moment-timezone';
import BarChartIcon from '@mui/icons-material/BarChart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ResearchReportIcon from '@mui/icons-material/Assessment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Header, CompanyInfo } from '../../Style/CompanyStyle';
import { extractCompanyInfo, fetchFavoriteCompanies, handleAddFavorite, fetchDataByView, handleReportClick, handleResearchClick, handleTrendClick } from './companyUtils';
import RenderItem from './NewsRenderItem/RenderItem';
import RenderFinancialReport from './FinancialReportRenderItem/RenderFinancialReport';
import RenderResearchItem from './ResearchRenderItem/RenderResearchItem';
import './CompanyPage.css';
import CalendarPicker from './CalendarPicker/CalendarPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import TrendDataRender from './TrendDataRender/TrendDataRender'; // 引入新组件

const CACHE_EXPIRATION_TIME = 30 * 60 * 1000; // 30 minutes

const CompanyPage = () => {
    const { companyName } = useParams();
    const navigate = useNavigate();
    const [selectedView, setSelectedView] = useState('新闻'); // Default to '新闻'
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [favoritesLoading, setFavoritesLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [favoriteCompanies, setFavoriteCompanies] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [selectedDate, setSelectedDate] = useState(getDefaultDate());
    const [pdfLoading, setPdfLoading] = useState(false); // Add loading state for PDF
    const [cleanCompanyName, setCleanCompanyName] = useState('');
    const [stockType, setStockType] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // 每页显示的项目数

    const calendarIconRef = useRef(null);

    const handleViewChange = (event, newView) => {
        if (newView !== null) {
            setSelectedView(newView);
        }
    };

    const handleDateSelect = (date) => {
        if (date) {
            setSelectedDate(date);
        }
    };

    function getDefaultDate() {
        // const beijingTime = moment.tz("Asia/Shanghai");
        //
        // if (beijingTime.hour() < 8) {
        //     beijingTime.subtract(1, 'day');
        // }
        //
        // return beijingTime.format('YYYY-MM-DD');
        return "2024-09-12"; // 固定返回 9月12号
    }

    const isCacheValid = (timestamp) => {
        const currentTime = new Date().getTime();
        return (currentTime - timestamp) < CACHE_EXPIRATION_TIME;
    };

    const fetchNews = useCallback(async (company, date) => {
        setLoading(true);
        const formattedDate = date.replace(/-/g, '');

        try {
            const cacheKey = `newsData-${company}-${date}`;
            const cachedData = JSON.parse(localStorage.getItem(cacheKey));
            const cachedTimestamp = parseInt(localStorage.getItem(`${cacheKey}-timestamp`), 10);

            if (cachedData && cachedTimestamp && isCacheValid(cachedTimestamp)) {
                setData(cachedData);
                setLoading(false);
                return;
            }

            const data = await fetchDataByView('新闻', company, formattedDate);
            const currentTime = new Date().getTime();

            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(`${cacheKey}-timestamp`, currentTime.toString());
            setData(data);
        } catch (error) {
            console.error("Error fetching news data:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const loadFavorites = async () => {
            try {
                const favorites = await fetchFavoriteCompanies();
                setFavoriteCompanies(favorites);
            } catch (error) {
                console.error('Failed to fetch favorite companies:', error);
            } finally {
                setFavoritesLoading(false);
            }
        };

        loadFavorites();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setData([]);

            const { companyName: extractedCompanyName, stockCode } = extractCompanyInfo(companyName);
            const determinedStockType = determineStockType(stockCode);

            // 设置到 state 中
            setCleanCompanyName(extractedCompanyName);
            setStockType(determinedStockType);

            try {
                const apiDate = selectedDate.replace(/-/g, '');
                if (selectedView === '新闻') {
                    await fetchNews(extractedCompanyName, selectedDate);
                } else if (selectedView === '趋势') {
                    await handleTrendClick(extractedCompanyName, determinedStockType, (trendData) => {
                        if (trendData && trendData.trend_data) {
                            setData(trendData.trend_data); // 设置为趋势数据的对象
                        }
                    }, setLoading);
                } else {
                    const data = await fetchDataByView(selectedView, extractedCompanyName, apiDate, determinedStockType);
                    setData(data);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedView, selectedDate, companyName, fetchNews]);


    useEffect(() => {
        setCurrentPage(1); // 当 selectedView 变化时，重置页码为1
    }, [selectedView]);

    useEffect(() => {
        setCurrentPage(1); // 当 data 变化时，重置页码为1
    }, [data]);

    const determineStockType = (stockCode) => {
        if (stockCode.endsWith('.HK')) {
            return 'stockHk';
        } else if (stockCode.match(/^\d{6}$/)) {
            return 'stockA';
        } else {
            return 'stockUs';
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleReportItemClick = (item) => {
        const { companyName: cleanCompanyName, stockCode } = extractCompanyInfo(companyName);
        const stockType = determineStockType(stockCode);
        handleReportClick(item, cleanCompanyName, stockType, navigate, setPdfLoading);
    };

    const handleResearchItemClick = (item) => {
        const { companyName: cleanCompanyName, stockCode } = extractCompanyInfo(companyName);
        const stockType = determineStockType(stockCode);
        handleResearchClick(item, cleanCompanyName, stockType, navigate, setPdfLoading);
    };

    const { companyName: displayName, stockCode } = extractCompanyInfo(companyName);

    const isFavorite = favoriteCompanies.some(fav => fav.item_name === displayName && fav.stock_code === stockCode);

    // 计算总页数，趋势数据不需要分页
    const totalPages = Array.isArray(data) ? Math.ceil(data.length / itemsPerPage) : 0;

    // 计算当前页的数据，仅在非趋势视图下使用分页
    const currentItems = Array.isArray(data) ? data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : [];

    if (favoritesLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div className="page-container"> {/* 包裹整个页面内容 */}
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: 4, width: '100%', maxWidth: '1200px', margin: '0 auto', height: '100vh', boxSizing: 'border-box' }}>
                <Header sx={{ marginLeft: '-7.8px', marginRight: "8px" , marginTop: '11px' }}>
                    <CompanyInfo>
                        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                            {displayName} ({stockCode})
                        </Typography>

                        <Button
                            variant="outlined"
                            startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                            sx={{ transform: 'translateY(-5px) translateX(10px)' }}
                            onClick={async (e) => {
                                e.stopPropagation();
                                await handleAddFavorite({ companyName: displayName, stockCode }, favoriteCompanies, setFavoriteCompanies, setSnackbarSeverity, setSnackbarMessage, setSnackbarOpen);
                            }}
                        >
                            {isFavorite ? '已收藏' : '收藏'}
                        </Button>
                    </CompanyInfo>
                </Header>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <ToggleButtonGroup
                        value={selectedView}
                        exclusive
                        onChange={handleViewChange}
                        aria-label="view selection"
                        sx={{ marginTop: '-50px' }}
                    >
                        <ToggleButton value="财报" aria-label="财报">
                            <BarChartIcon sx={{ marginRight: '8px' }} />
                            财报
                        </ToggleButton>
                        <ToggleButton value="新闻" aria-label="新闻">
                            <NewspaperIcon sx={{ marginRight: '8px' }} />
                            新闻
                        </ToggleButton>
                        <ToggleButton value="研报" aria-label="研报">
                            <ResearchReportIcon sx={{ marginRight: '8px' }} />
                            研报
                        </ToggleButton>
                        <ToggleButton value="趋势" aria-label="趋势">
                            <FontAwesomeIcon icon={faChartLine} style={{ marginRight: '8px' }} />
                            趋势
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Box sx={{ display: 'flex', alignItems: 'center', minHeight: '48px' }}>
                        {selectedView === '新闻' && (
                            <>
                                <Typography
                                    sx={{
                                        marginRight: 2,
                                        fontSize: { xs: '0.5em', sm: '1em' }, // 在手机端（xs）将字体缩小一倍
                                    }}
                                >
                                    {selectedDate ? `当前选择日期: ${selectedDate}` : '未选择日期'}
                                </Typography>
                                <IconButton
                                    ref={calendarIconRef}
                                    onClick={() => {
                                        const rect = calendarIconRef.current.getBoundingClientRect();
                                        setCalendarPosition({
                                            top: rect.bottom + window.scrollY + 5,
                                            left: rect.left + window.scrollX - 200,
                                        });
                                        setShowCalendar(!showCalendar);
                                    }}
                                >
                                    <CalendarTodayIcon />
                                </IconButton>
                            </>
                        )}
                    </Box>
                </Box>

                {showCalendar && selectedView === '新闻' && (
                    // <CalendarPicker
                    //     onDateSelect={handleDateSelect}
                    //     position={calendarPosition}
                    //     maxDate={new Date().toISOString().slice(0, 10)}
                    //     hideCalendar={() => setShowCalendar(false)}
                    // />

                    <CalendarPicker
                        onDateSelect={handleDateSelect}
                        position={calendarPosition}
                        maxDate="2024-09-12" // 允许选择的最大日期是当前日期
                        defaultDate="2024-09-12" // 默认显示 9 月 12 日
                        hideCalendar={() => setShowCalendar(false)}
                    />
                )}

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box className="content-container" sx={{ overflowY: 'auto' }}>
                        {selectedView === '趋势' && data && !Array.isArray(data) ? (
                            <TrendDataRender trendData={data} stockType={stockType} cleanCompanyName={cleanCompanyName} />
                        ) : currentItems.length === 0 ? (
                            <Box sx={{
                                textAlign: 'center',
                                marginTop: 4,
                                padding: 4,
                                borderRadius: 2,
                                backgroundColor: '#f1f1f1',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '200px'
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                                    {selectedView === '新闻' && (
                                        <>
                                            <NewspaperIcon sx={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无新闻内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                    {selectedView === '财报' && (
                                        <>
                                            <BarChartIcon sx={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无财报内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                    {selectedView === '研报' && (
                                        <>
                                            <ResearchReportIcon sx={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无研报内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                    {selectedView === '趋势' && (
                                        <>
                                            <FontAwesomeIcon icon={faChartLine} style={{ fontSize: 60, color: '#888888' }} />
                                            <Typography variant="h6" color="textSecondary" sx={{ mt: 1 }}>
                                                暂无趋势内容，请稍后再试。
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                                <Typography variant="body2" color="textSecondary">
                                    请稍后再试或更换其他选项。
                                </Typography>
                            </Box>
                        ) : (
                            currentItems.map(item => (
                                selectedView === '财报' ? (
                                    <RenderFinancialReport key={item.id} item={item} displayName={displayName} onReportClick={handleReportItemClick} />
                                ) : selectedView === '研报' ? (
                                    <RenderResearchItem key={item.id} item={item} displayName={displayName} onReportClick={handleResearchItemClick} />
                                ) : (
                                    <RenderItem key={item.id} item={item} displayName={displayName} selectedView={selectedView} />
                                )
                            ))
                        )}

                        {totalPages > 1 && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={(event, value) => setCurrentPage(value)}
                                    color="primary"
                                    showFirstButton
                                    showLastButton
                                />
                            </Box>
                        )}
                    </Box>
                )}

                {pdfLoading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.7)', zIndex: 9999 }}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </div>
    );
};

export default CompanyPage;
