import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Tabs, Tab, Box, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useEffect } from 'react';

const PaymentOption = ({ title, price, description, isSelected, onClick }) => (
    <Box
        onClick={onClick}
        sx={{
            border: '2px solid',
            borderColor: isSelected ? '#3f51b5' : 'transparent',
            borderRadius: '12px',
            padding: '20px',
            cursor: 'pointer',
            transition: 'all 0.3s ease-in-out',
            backgroundColor: isSelected ? '#e3f2fd' : '#fff',
            boxShadow: isSelected ? '0 4px 12px rgba(0, 0, 0, 0.1)' : 'none',
            '&:hover': {
                borderColor: '#1e88e5',
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}
    >
        <Box>
            <Typography variant="h6" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#1e88e5' }}>
                {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                {description}
            </Typography>
        </Box>
        <Typography variant="h5" sx={{ marginLeft: '16px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', color: '#424242' }}>
            ¥{price}
        </Typography>
    </Box>
);

const MembershipModal = ({ visible, onClose }) => {
    useEffect(() => {
        if (visible) {
            // 隐藏右侧滚动条
            document.body.style.overflow = 'hidden';
        } else {
            // 恢复右侧滚动条
            document.body.style.overflow = '';
        }
        return () => {
            // 确保在组件卸载时恢复默认滚动行为
            document.body.style.overflow = '';
        };
    }, [visible]);


    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedCredit, setSelectedCredit] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };



    const creditOptions = [
        { title: '400积分', price: '30', description: '适合轻度用户' },
        { title: '800积分', price: '50', description: '最受欢迎' },
        { title: '2000积分', price: '90', description: '最具性价比' },
    ];

    const planOptions = [
        {
            title: '免费会员',
            price: '0',
            description: (
                <>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天尊享 3 次免费 AI 搜索引擎服务，体验智能搜索
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天 3 次免费学习平台访问，提升专业技能和知识
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天 3 次免费 PDF 财报分析，深入洞察市场动态
                    </Typography>
                </>
            )
        },
        {
            title: '基础会员',
            price: '30',
            description: (
                <>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天尊享 10 次AI 搜索引擎服务，精准获取所需信息
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天 10 次 学习平台提问，快速提升专业技能和知识
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天 10 次 PDF财报分析，深入洞察市场动态
                    </Typography>
                </>
            )
        },
        {
            title: '高级会员',
            price: '80',
            description: (
                <>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        每天高达 100 次AI搜索引擎和50次财报分析及50次学习平台提问
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        解锁公司趋势分析高级功能，洞察市场走向，抢占先机
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        使用更长上下文的 GPT 模型，享受更智能的对话体验
                    </Typography>
                </>
            )
        },
    ];

    const creditInfo = (
        <Box mt={2} mb={2} textAlign="center">
            <Typography variant="body1" color="textPrimary" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>
                积分使用指南：
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                每次财报分析：仅需 1 积分，轻松获取关键数据
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                每次学习平台使用：1 积分，让您的学习更高效
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Roboto, sans-serif' }}>
                每次智投引擎使用：1 积分，及时获取最新咨询
            </Typography>
        </Box>
    );

    return (
        <Dialog open={visible} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>
                    会员中心
                </Typography>
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    centered
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            borderBottom: '2px solid #e0e0e0',
                        },
                        '& .MuiTab-root': {
                            fontFamily: 'Roboto, sans-serif',
                            fontWeight: 'bold',
                            color: '#1e88e5',
                            '&.Mui-selected': {
                                color: '#1e88e5',
                            },
                        },
                    }}
                >
                    <Tab icon={<WalletIcon />} label="购买积分" />
                    <Tab icon={<CreditCardIcon />} label="会员订阅" />
                </Tabs>
                <Box mt={3}>
                    {selectedTab === 0 && (
                        <>
                            {creditInfo}
                            <Box display="flex" flexDirection="column" gap={2}>
                                {creditOptions.map((option, index) => (
                                    <PaymentOption
                                        key={index}
                                        {...option}
                                        isSelected={selectedCredit === index}
                                        onClick={() => setSelectedCredit(index)}
                                    />
                                ))}
                            </Box>
                        </>
                    )}
                    {selectedTab === 1 && (
                        <Box display="flex" flexDirection="column" gap={2}>
                            {planOptions.map((option, index) => (
                                <PaymentOption
                                    key={index}
                                    {...option}
                                    isSelected={selectedPlan === index}
                                    onClick={() => setSelectedPlan(index)}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Box mt={4} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        size="large"
                        sx={{
                            textTransform: 'none',
                            background: 'linear-gradient(45deg, #1e88e5 30%, #42a5f5 90%)',
                            color: '#fff',
                            borderRadius: '8px',
                            padding: '12px 24px',
                            '&:hover': {
                                background: 'linear-gradient(45deg, #1e88e5 30%, #42a5f5 90%)',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                            },
                        }}
                        onClick={onClose}
                    >
                        使用微信支付
                    </Button>
                </Box>
                <Typography variant="body2" color="textSecondary" align="center" mt={2} sx={{ fontFamily: 'Roboto, sans-serif' }}>
                    点击按钮后，将跳转至微信支付
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default MembershipModal;