import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid, CardContent, Button, InputAdornment, CircularProgress, Snackbar, Alert, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { StyledCard, BookCover, SearchBox, Header } from '../Style/BookStyle'; // 确保路径正确
import useBooks from './useBooks'; // Import the custom hook

const categories = [
    { name: '投资', books: ['彼得林奇的成功投资', '滚雪球-巴菲特和他的财富人生', '投资最重要的事', '投资者的未来', '安全边际'] },
    { name: '经济金融', books: ['国富论', '金融炼金术', '黑天鹅', '股市真规则', '漫步华尔街'] },
    { name: '理财', books: ['百万富翁快车道', '富爸爸穷爸爸', '小狗钱钱', '富爸爸投资指南', '邻家的百万富翁'] }
];

function LearnPage() {
    const {
        searchQuery,
        loading,
        snackbarOpen,
        snackbarMessage,
        snackbarSeverity,
        favoriteBooks,
        handleSearchChange,
        handleBookClick,
        handleAddFavorite,
        handleCloseSnackbar
    } = useBooks();

    const [imagesLoaded, setImagesLoaded] = useState(false);

    // 使用 useMediaQuery 检测是否为手机端
    const isMobile = useMediaQuery('(max-width:600px)');

    const filteredCategoriesWithSearch = categories.map(category => ({
        ...category,
        books: category.books.filter(book => book.toLowerCase().includes(searchQuery.toLowerCase()))
    }));

    useEffect(() => {
        const preloadImages = () => {
            const images = filteredCategoriesWithSearch.flatMap(category =>
                category.books.map(book =>
                    new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = `./BookImages/${book}.jpg`;
                        img.onload = resolve;
                        img.onerror = reject;
                    })
                )
            );

            Promise.all(images)
                .then(() => setImagesLoaded(true))
                .catch(err => console.error('Error loading images', err));
        };

        preloadImages();
    }, [filteredCategoriesWithSearch]);

    if (loading || !imagesLoaded) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                padding: 4,
                maxWidth: '1200px',
                margin: '0 auto',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                ...(isMobile && { marginLeft: '40px' }), // 仅在手机端时添加左边距
            }}
        >
            <Header>
                <Typography variant="h4" gutterBottom>
                    书籍
                </Typography>
                <SearchBox
                    variant="outlined"
                    placeholder="搜索书籍"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Header>
            <Grid container spacing={1}>
                {filteredCategoriesWithSearch.map((category) => (
                    <Box key={category.name} sx={{ marginBottom: 4, width: '100%' }}>
                        <Typography variant="h5" gutterBottom>
                            {category.name}
                        </Typography>
                        <Grid container spacing={1}>
                            {category.books.map((book) => (
                                <Grid item xs={12} sm={6} md={4} key={book}>
                                    <StyledCard sx={{ minWidth: '280px', maxWidth: '320px' }} onClick={() => handleBookClick(book)}>
                                        <BookCover>
                                            <img src={`./BookImages/${book}.jpg`} alt={book} />
                                        </BookCover>
                                        <CardContent>
                                            <Typography variant="body1" gutterBottom>
                                                {book}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                startIcon={favoriteBooks.includes(book) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                                onClick={(e) => { e.stopPropagation(); handleAddFavorite(book); }}
                                            >
                                                {favoriteBooks.includes(book) ? '已收藏' : '收藏'}
                                            </Button>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            ))}
                            {Array.from({ length: 3 - category.books.length % 3 }).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} key={`placeholder-${index}`} style={{ visibility: 'hidden' }}>
                                    <StyledCard sx={{ minWidth: '280px', maxWidth: '320px' }}>
                                        <BookCover>
                                            <img src={`./images/placeholder.jpg`} alt="Placeholder" />
                                        </BookCover>
                                        <CardContent>
                                            <Typography variant="body1" gutterBottom>
                                                Placeholder
                                            </Typography>
                                            <Button variant="outlined" startIcon={<FavoriteBorderIcon />}>
                                                收藏
                                            </Button>
                                        </CardContent>
                                    </StyledCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                ))}
            </Grid>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LearnPage;
