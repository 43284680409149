import React, { useState, useEffect } from 'react';
import { Drawer, Box } from '@mui/material';
import axios from 'axios';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';
import UserProfile from './UserProfile';
import LoginRegisterModal from './LoginRegisterModal/LoginRegisterModal';
import 'bootstrap/dist/css/bootstrap.min.css';
import './scrollbar.css';
import serverAddress from "../../ServerAddress";

function Sidebar({ isOpen, toggleSidebar, isLoggedIn, setIsLoggedIn, username, setUsername }) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedModule, setSelectedModule] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const [autoCollapse, setAutoCollapse] = useState(true);

    // 处理屏幕尺寸变化，自动折叠侧边栏
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768 && autoCollapse) {
                if (isOpen) toggleSidebar();
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isOpen, toggleSidebar, autoCollapse]);

    // 验证用户的 JWT 令牌
    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem('access_token');
            if (token) {
                try {
                    const response = await axios.post(`${serverAddress()}/auth/verify_token`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    if (response.data.message === 'Token verified successfully!') {
                        setIsLoggedIn(true);
                        setUsername(response.data.username);
                    } else {
                        setIsLoggedIn(false);
                    }
                } catch (err) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user_id');
                    localStorage.removeItem('username');
                    setIsLoggedIn(false);
                }
            } else {
                setIsLoggedIn(false);
            }
            setLoading(false);
        };

        verifyToken();
    }, [setIsLoggedIn, setUsername]);

    // 打开和关闭登录/注册模态框
    const handleOpenModal = () => {
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setError('');
        setSuccess('');
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // 处理用户头像点击事件
    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // 处理用户登出
    const handleLogout = () => {
        setIsLoggedIn(false);
        setUsername('');
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('username');
        handleMenuClose();
    };

    // 显示用户名（超过7个字符的部分用省略号表示）
    const displayUsername = (name) => {
        return name.length > 7 ? `${name.substring(0, 7)}...` : name;
    };

    const handleModuleClick = (module) => {
        setSelectedModule(module);
    };

    const handleToggleSidebar = () => {
        setAutoCollapse(false);
        toggleSidebar();
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflowX: 'hidden' }}>
            <Drawer
                variant="permanent"
                anchor="left"
                open={isOpen}
                className="scrollbar-container"
                sx={{
                    width: isOpen ? 240 : 56,
                    flexShrink: 0,
                    height: '100vh',
                    overflowX: 'hidden',
                    overflowY: isOpen ? 'auto' : 'hidden',
                    '& .MuiDrawer-paper': {
                        width: isOpen ? 240 : 56,
                        overflowX: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fafafa',
                        height: '100vh',
                        overflowY: isOpen ? 'auto' : 'hidden',
                    }
                }}
            >
                <SidebarHeader isOpen={isOpen} handleToggleSidebar={handleToggleSidebar} />
                <Box className="scrollbar-container" sx={{ flexGrow: 1, overflowX: 'hidden', overflowY: isOpen ? 'auto' : 'hidden' }}>
                    <SidebarMenu isOpen={isOpen} selectedModule={selectedModule} handleModuleClick={handleModuleClick} />
                </Box>
                <UserProfile
                    isOpen={isOpen}
                    isLoggedIn={isLoggedIn}
                    loading={loading}
                    username={username}
                    handleAvatarClick={handleAvatarClick}
                    anchorEl={anchorEl}
                    handleMenuClose={handleMenuClose}
                    handleLogout={handleLogout}
                    handleOpenModal={handleOpenModal}
                    displayUsername={displayUsername}
                />
            </Drawer>
            <LoginRegisterModal
                open={isModalOpen}
                onClose={handleCloseModal}
                username={username}
                setUsername={setUsername}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                error={error}
                setError={setError}
                success={success}
                setSuccess={setSuccess}
                setIsLoggedIn={setIsLoggedIn}
            />
        </div>
    );
}

export default Sidebar;
