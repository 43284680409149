import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tabs, Tab, Box, Typography } from '@mui/material';
import { AccountCircle, PersonAdd } from '@mui/icons-material';
import axios from 'axios';
import serverAddress from "../../../ServerAddress";

function LoginRegisterModal({
                                open,
                                onClose,
                                username,
                                setUsername,
                                password,
                                setPassword,
                                confirmPassword,
                                setConfirmPassword,
                                error,
                                setError,
                                success,
                                setSuccess,
                                setIsLoggedIn
                            }) {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (open) {
            setActiveTab(0);
            setError('');
            setSuccess('');
        }
    }, [open, setError, setSuccess]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        setError('');
        setSuccess('');
    };

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setError('密码和确认密码不一致');
            return;
        }

        if (username.includes(' ')) {
            setError('用户名不能包含空格');
            return;
        }

        if (password.includes(' ')) {
            setError('密码不能包含空格');
            return;
        }

        try {
            await axios.post(`${serverAddress()}/auth/register_user`, {
                username,
                password
            });

            // 处理注册成功的情况
            setSuccess('注册成功，请登录！');
            setError('');  // 清除错误消息
            // 保持窗口打开状态，不关闭
        } catch (err) {
            // 从 response.data.detail 获取错误消息
            const errorMessage = err.response?.data?.error || err.response?.data?.detail || '注册失败';
            console.log("error is", errorMessage)
            if (errorMessage === '400: Username already exists') {
                setError('用户名已存在');
            } else {
                setError(errorMessage);
            }
        }
    };



    const handleLogin = async () => {
        if (!username || !password) {
            setError('用户名和密码不能为空');
            return;
        }

        try {
            const response = await axios.post(`${serverAddress()}/auth/verify_user`, {
                username,
                password
            }, {
                withCredentials: true
            });

            if (response.data.access_token) {
                setSuccess('用户登录成功！');
                setError('');
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('username', username);
                setIsLoggedIn(true);

                try {
                    const userIdResponse = await axios.get(`${serverAddress()}/recent/get_user_id`, {
                        params: { username },
                        headers: {
                            Authorization: `Bearer ${response.data.access_token}`
                        }
                    });

                    if (userIdResponse.data.user_id) {
                        localStorage.setItem('user_id', userIdResponse.data.user_id);
                        console.log('User ID updated:', userIdResponse.data.user_id);
                    } else {
                        setError('获取用户ID失败');
                    }
                } catch (err) {
                    setError('获取用户ID失败');
                }

                onClose();
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error;
            if (errorMessage === 'Invalid username or password') {
                setError('用户名或密码无效');
            } else {
                setError(errorMessage || '登录失败');
            }
        }
    };


    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>欢迎加入内测</DialogTitle>
            <DialogContent>
                <Tabs value={activeTab} onChange={handleTabChange} centered>
                    <Tab icon={<AccountCircle />} label="登录" />
                    <Tab icon={<PersonAdd />} label="注册" />
                </Tabs>
                {activeTab === 0 && (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="用户名"
                            type="text"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="密码"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
                {activeTab === 1 && (
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="用户名"
                            type="text"
                            fullWidth
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="密码"
                            type="password"
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            label="确认密码"
                            type="password"
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {error && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                        )}
                        {success && (
                            <Typography color="primary" variant="body2" sx={{ mt: 1 }}>
                                {success}
                            </Typography>
                        )}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    取消
                </Button>
                <Button
                    onClick={activeTab === 0 ? handleLogin : handleRegister}
                    color="primary"
                >
                    {activeTab === 0 ? '登录' : '注册'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LoginRegisterModal;
