import React, { useState, useEffect } from 'react';
import { Typography, Divider, Avatar, Box, Link, Chip, IconButton, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FaLink } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import './ResponseDisplay.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ResponseDisplay({ responses, isWaitingResponse, chatSessions = [], initialQueryResponse }) {
    const [expanded, setExpanded] = useState([]);
    const [allResponses, setAllResponses] = useState([]);

    useEffect(() => {
        const previousResponses = chatSessions.flatMap(session =>
            (session.chats || []).map(chat => ({
                userMessage: chat.title,
                text: chat.content,
                urls: Object.fromEntries((chat.urls || []).map(url => [url.title, url.url]))
            }))
        );

        const combinedResponses = [...previousResponses, ...responses];
        if (initialQueryResponse) {
            combinedResponses.unshift(initialQueryResponse);
        }

        setAllResponses(combinedResponses);
        setExpanded(new Array(combinedResponses.length).fill(false));
    }, [responses, chatSessions, initialQueryResponse]);

    const handleExpandClick = (index) => {
        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = !newExpanded[index];
            return newExpanded;
        });
    };

    // 处理 markdown 中的 [1], [2], [3] 引用，使用 CSS 美化
    const renderTextWithCitations = (text, urls) => {
        // 正则表达式分别匹配 [] 和 【】 中的数字，不需要转义
        const citationRegex = /\[(\d+)\]|【(\d+)】/g;
        return text.replace(citationRegex, (match, citationNumber1, citationNumber2) => {
            // 获取引用中的数字，优先使用 citationNumber1，否则使用 citationNumber2
            const citationNumber = citationNumber1 || citationNumber2;

            // 获取对应的 URL
            const urlEntries = Object.entries(urls);
            const url = urlEntries[parseInt(citationNumber) - 1] ? urlEntries[parseInt(citationNumber) - 1][1] : '#';

            // 将引用替换为带有圆圈的链接，并确保圆形样式和黑色字体
            return `<sup style="background-color: #d0d0d0; border-radius: 50%; padding: 2px 6px; display: inline-block; margin: 0 4px; cursor: pointer; font-size: 12px; color: black; line-height: 1; text-align: center;">
            <a href="${url}" target="_blank" rel="noopener noreferrer" style="text-decoration: none; color: black;">${citationNumber}</a>
        </sup>`;
        });
    };


    return (
        <>
            {allResponses.map((response, index) => (
                <div key={`${response.userMessage}-${response.timestamp}-${index}`} className="response-container">
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{
                            mb: 2,
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            maxWidth: '100%',
                            overflowWrap: 'break-word',
                            hyphens: 'auto',
                            color: 'black',
                            textAlign: 'left',
                            marginBottom: '20px'  // 增加标题的下间距
                        }}
                    >
                        {response.userMessage}
                    </Typography>

                    {/* 来源信息 */}
                    {response.urls && Object.keys(response.urls).length > 0 && (
                        <div>
                            <Box display="flex" alignItems="center" mt={2} justifyContent="space-between">
                                <Box display="flex" alignItems="center">
                                    <Avatar sx={{ width: 24, height: 24, backgroundColor: 'white' }}>
                                        <FaLink style={{ color: 'black' }} />
                                    </Avatar>
                                    <Typography variant="body1" sx={{ ml: 1, color: 'black', textAlign: 'left' }}>来源:</Typography>
                                </Box>

                                {/* 只有当 urls 数量大于 5 时，才显示收起/展开按钮 */}
                                {Object.keys(response.urls).length > 5 && (
                                    <Box display="flex" alignItems="center">
                                        <Typography sx={{ color: 'black', mr: 1 }}>
                                            {expanded[index] ? "收起" : "展开"} {/* 修正的文本逻辑 */}
                                        </Typography>
                                        <Tooltip title={expanded[index] ? "收起" : "展开"} arrow>
                                            <IconButton
                                                onClick={() => handleExpandClick(index)}
                                                sx={{ color: 'black', '&:hover': { color: '#3f51b5' } }}  // 悬停时颜色变化
                                            >
                                                <ExpandMoreIcon
                                                    sx={{
                                                        transform: expanded[index] ? 'rotate(0deg)' : 'rotate(90deg)', // 收起状态显示箭头
                                                        transition: 'transform 0.2s'
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                            <Box mt={2} sx={{ ml: '24px', marginBottom: '20px' }}>  {/* 增加urls底部的margin */}
                                {Object.entries(response.urls).slice(0, expanded[index] ? Object.keys(response.urls).length : 5).map(([title, url], i) => (
                                    <Box key={i} display="flex" alignItems="center" sx={{ mb: 1 }}>
                                        <Chip
                                            label={(i + 1).toString()}  // 将数字转换为字符串
                                            size="small"
                                            sx={{ backgroundColor: 'black', color: 'white', mr: 1, borderRadius: '50%', minWidth: 32, minHeight: 24, fontSize: '0.75rem' }}  // 使用 borderRadius 设置为 '50%'，同时设置 minWidth 和 minHeight
                                        />
                                        <Typography sx={{ color: 'black', textAlign: 'left' }}>
                                            <Link href={url} target="_blank" rel="noopener noreferrer" sx={{ color: 'inherit', textDecoration: 'none' }}>
                                                {title}
                                            </Link>
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </div>
                    )}

                    {/* 答案部分 */}
                    <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                        <Avatar sx={{ width: 24, height: 24, backgroundColor: 'black' }}>
                            <CheckCircleIcon sx={{ color: 'white' }} />
                        </Avatar>
                        <Typography variant="body1" sx={{ ml: 1, color: 'black', textAlign: 'left' }}>答案:</Typography>
                    </Box>
                    <Box display="flex" alignItems="flex-start" sx={{ mb: 2, position: 'relative' }}>
                        {index === allResponses.length - 1 && isWaitingResponse && (
                            <div className="blinking-dot"></div>
                        )}
                        <div
                            className={index === allResponses.length - 1 && isWaitingResponse ? 'with-blinking-dot' : ''}
                        >
                            {/* 使用 ReactMarkdown 渲染并支持 GFM 和 Raw HTML */}
                            <ReactMarkdown
                                children={renderTextWithCitations(response.text, response.urls)}
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[remarkGfm]}
                            />
                        </div>
                    </Box>

                    {index < allResponses.length - 1 &&
                        <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.12)', margin: '20px 0' }} />}
                </div>
            ))}
        </>
    );
}

export default ResponseDisplay;
