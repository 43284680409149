// SidebarMenu.js
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import HistoryIcon from '@mui/icons-material/History';
import RecentChats from '../RecentChats/RecentChats';
import { useTheme } from '@mui/material/styles';

function SidebarMenu({ isOpen, selectedModule, handleModuleClick }) {
    const theme = useTheme();

    const menuItems = [
        {
            text: '首页',
            icon: <HomeIcon fontSize="medium" />,
            to: '/search',
            module: 'home',
        },
        {
            text: '大盘新闻',
            icon: <PublicIcon fontSize="medium" />,
            to: '/hotnews',
            module: 'news',
        },
        {
            text: '公司',
            icon: <DescriptionIcon fontSize="medium" />,
            to: '/analysis',
            module: 'company',
        },
        {
            text: '学习',
            icon: <SchoolIcon fontSize="medium" />,
            to: '/learn',
            module: 'learn',
        },
    ];

    // 定义 RecentChats 展开的最大高度
    const recentChatsMaxHeight = 200; // 根据需要调整此值

    return (
        <List>
            {menuItems.map((item) => (
                <ListItem
                    button
                    key={item.text}
                    component={RouterLink}
                    to={item.to}
                    sx={{
                        mb: 1,
                        backgroundColor: selectedModule === item.module ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        transition: theme.transitions.create(['background-color'], {
                            duration: theme.transitions.duration.standard,
                        }),
                    }}
                    onClick={() => handleModuleClick(item.module)}
                >
                    <ListItemIcon sx={{ minWidth: 56 }}>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={item.text}
                        sx={{
                            ml: -1,
                            color: '#000',
                            fontSize: '1rem',
                            whiteSpace: 'nowrap',
                            opacity: isOpen ? 1 : 0,
                            width: isOpen ? 'auto' : 0,
                            transition: theme.transitions.create(['opacity', 'width'], {
                                duration: theme.transitions.duration.standard,
                            }),
                            overflow: 'hidden',
                        }}
                    />
                </ListItem>
            ))}
            <ListItem
                button
                sx={{
                    mb: 1,
                    backgroundColor: selectedModule === 'recent' ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                    transition: theme.transitions.create(['background-color'], {
                        duration: theme.transitions.duration.standard,
                    }),
                }}
                onClick={() => handleModuleClick('recent')}
            >
                <ListItemIcon sx={{ minWidth: 56 }}>
                    <HistoryIcon fontSize="medium" />
                </ListItemIcon>
                <ListItemText
                    primary="最近"
                    sx={{
                        ml: -1,
                        color: '#333',
                        fontSize: '1rem',
                        whiteSpace: 'nowrap',
                        opacity: isOpen ? 1 : 0,
                        width: isOpen ? 'auto' : 0,
                        transition: theme.transitions.create(['opacity', 'width'], {
                            duration: theme.transitions.duration.standard,
                        }),
                        overflow: 'hidden',
                    }}
                />
            </ListItem>
            <Box
                sx={{
                    pl: 4,
                    opacity: isOpen ? 1 : 0,
                    maxHeight: isOpen ? recentChatsMaxHeight : 0,
                    transition: theme.transitions.create(['opacity', 'max-height'], {
                        duration: theme.transitions.duration.standard,
                        easing: theme.transitions.easing.easeOut,
                    }),
                    overflow: 'hidden',
                }}
            >
                <RecentChats />
            </Box>
        </List>
    );
}

export default SidebarMenu;
